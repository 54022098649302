@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  button,
  a {
    @apply focus:outline-none;
  }
}

@layer components {
  .base-input-wrapper {
    @apply flex flex-col items-start;
  }

  .base-label {
    @apply mb-2;
    @apply text-sm font-semibold leading-[19px] uppercase;
  }

  .base-input {
    @apply w-full bg-bg-input py-4 px-5 rounded-4 border border-border-input md:text-xl focus:ring-2 focus:ring-primary-500 focus:border-primary-500 transition-all duration-150;
  }

  .step-heading {
    @apply text-[28px] leading-[36px] md:text-[36px] md:leading-[50px];
  }
}
